/**
 * Direttiva che costruisce una card rappresentante l'oggetto
 */
import { Item, User } from "atfcore-commonclasses";

export interface ILibraryCardDirectiveScope extends ng.IScope {
	libraryCard: any;
	goToItemDetail: Function;
	chartOptions: any;
	isSoftSkill: boolean;
	isTechSkill: boolean;
	itemType: any;
	hourValue: any;
	techCompetences: any;
	softCompetences: any;
	hasSuggested: boolean;
	globalApplicationData: any;
	bgAcademyApplicationData: any;
	suggestedPerson: any;
	isLearningPlan: boolean;
	isProject: boolean;
	isSection: boolean;
	page: any;
	item: any;
	isItemOpen: boolean;
	percentageItem: string;
	suggestedFromAdmin: boolean;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	iconToShow: string;
	iconToShowAlt: string;
	isItemAlreadyCertified: boolean;
	isMandatory: boolean;
	mandatory: number;
	changeMandatory: Function;
	propedeuticReferenceId: string;
	isPropedeutic: boolean;
	hasPrerequisites: boolean;
	changePrerequisites: Function;
	propedeuticReferenceTitle: Function;
	propedeuticTitle: string;
	setPropedeuticOffCb: Function;
	prerequisites: any;
	propedeuticReferenceIds: Array<string>;
	checkPropedeuticReferenceIds: Function;
	referenceId: string;
	isItemDisabled: any;
	combinedCompetences: any;
	cardCover: any;
	currentItemType: any;
	currentItem: any;
	isScorm: boolean;
	isConsumed: boolean;
	deleteFunction: Function;
	changeOrderUpFunction: Function;
	changeOrderDownFunction: Function;
	isEditContainerItemAdmin: boolean;
	isRequired: boolean;
	isNotRequired: boolean;
	checkIfNotRequired: Function;
	bgDisabledClass: string;
	textDisabledClass: string;
	isNotDisabled: boolean;
	containerClass: string;
	opacityLayerClass: string;
	textWhiteDisabledClass: string;
	checkIfRequired: Function;
	showPropedeuticalItem: Function;
	showPropedeuticalItemToThis: Function;
	adminMode: boolean;
	isMifid: boolean;
}
angular.module('app').directive("libraryCard", ($window, $state, $sessionStorage, BgacademyApplicationData, GlobalApplicationData, moment, $stateParams) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			page: '@',
			libraryCard: '=',
			chartOptions: '=',
			isLearningPlanEditingMode: '@',
			mandatory: '=', // obbligatorietà
			propedeuticReferenceId: '=', // id dell'oggetto propedeutico per quello attuale
			propedeuticReferenceTitle: '&', // Titolo della referenza propedeutica
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			openItemDetail: '&',
			isItemChecked: '=',
			toggleItem: '=',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			isPropedeuticDisabled: '=',
			managePrerequisites: '=',
			propedeuticReferenceIds: '=', // lista dei propedeuticReferenceIds di ogni itemChilds 
			showPropedeuticalItemToThis: '&', // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
			currentItemType: '=',
			currentItem: '=',
			statusType: '=', // stato dell'oggetto prestabile
			deleteFunction: "&?",
			changeOrderUpFunction: "&?",
			changeOrderDownFunction: "&?",
			isNotDisabled: '='
		},
		link: link,
		templateUrl: 'app/shared/libraryCard/libraryCard.html'
	};
	function link($scope: ILibraryCardDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta di un itemChilds
		if (!$scope.libraryCard.referenceId) {
			$scope.item = $scope.libraryCard;
		} else {
			// Prima di salvare il childObject, recupero l'referenceId affinchè, in seguito, possa verifica se tale oggetto è propedeutico per qualche altro
			$scope.referenceId = $scope.libraryCard.referenceId;
			$scope.item = $scope.libraryCard.childObject;
		}

		// Verifica se è MiFID
		$scope.isMifid = false;

		$scope.adminMode = $sessionStorage.adminMode;

		if ($state.includes('app.bgacademyApp.editContainerItemAdmin') || $state.includes('app.bgacademyApp.createContainerItemAdmin')
			|| $state.includes('app.bgacademyApp.createSectionContainerItemAdmin') || $state.includes('app.bgacademyApp.editSectionContainerItemAdmin')) {
			$scope.isEditContainerItemAdmin = true;
		} else {
			$scope.isEditContainerItemAdmin = false;
		}

		// Classe CSS aggiunta allo sfondo quando l'Item è disabilitato
		$scope.bgDisabledClass = '';

		// Classe CSS aggiunta al testo quando l'Item è disabilitato
		$scope.textDisabledClass = '';

		// Verifico se è un oggetto scorm
		$scope.isScorm = $scope.item && $scope.item.itemType && ($scope.item.itemType == BgacademyApplicationData.constants.SCORM_FREE || $scope.item.itemType == BgacademyApplicationData.constants.SCORM_INVITE) ? true : false;

		// Salvo l'obbligatorietà
		$scope.isMandatory = !!$scope.mandatory;

		// Immagine di copertina
		$scope.cardCover = null;

		// Controllo se non è obbligatorio
		$scope.checkIfNotRequired = () => {
			if ($scope.isPropedeutic || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isNotRequired = true;
			} else {
				$scope.isNotRequired = false;
			}
		}

		// Verifico se questo oggetto è propedeutico per qualche altro oggetto
		$scope.isPropedeutic = false;
		$scope.checkPropedeuticReferenceIds = () => {
			if ($scope.propedeuticReferenceIds && $scope.propedeuticReferenceIds.length) {
				for (let m = 0; m < $scope.propedeuticReferenceIds.length; m++) {
					if ($scope.propedeuticReferenceIds[m] == $scope.referenceId) {
						$scope.isPropedeutic = true;
						break;
					} else {
						$scope.isPropedeutic = false;
					}
				}
			} else {
				$scope.isPropedeutic = false;
			}
			$scope.checkIfNotRequired();
		}
		if ($scope.propedeuticReferenceIds) {
			$scope.checkPropedeuticReferenceIds();
		}

		$scope.checkIfNotRequired();

		// Verifico se esiste un altro oggetto propedeutico per quello attuale
		$scope.prerequisites = {
			hasPrerequisites: $scope.propedeuticReferenceId ? true : false
		}

		// Callback che, se ho chiuso la modale prima di selezionare un oggetto a cui aggiugnere la propedeuiticità, spenge lo switch
		$scope.setPropedeuticOffCb = () => {
			$scope.prerequisites.hasPrerequisites = false;
		}

		// Se ho un item propedeutico a quello attuale, recupero il suo titolo
		if ($scope.prerequisites.hasPrerequisites) {
			$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
		}

		// Quando cambia l'oggetto propedeutico di quello attuale, devo recuperare il suo titolo
		$scope.$watch('propedeuticReferenceId', (newValue, oldValue) => {
			if (newValue) {
				$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
			}
		}, true);

		// Quando cambia la lista degli oggetti propedeutici, devo aggiornare le etichette di obbligatorietà poichè l'item attuale potrebbe essere diventato
		// un item propedeutico a qualcos'altro
		$scope.$watch('propedeuticReferenceIds', (newValue, oldValue) => {
			if (newValue) {
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
		$scope.changeMandatory = () => {
			if ($scope.isMandatory == true) {
				$scope.mandatory = 0;
				$scope.isMandatory = false;
			} else {
				$scope.mandatory = 1;
				$scope.isMandatory = true;
			}

			$scope.checkIfRequired();
		}

		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Elenco di tag delle competenze tecniche
		$scope.techCompetences = [];

		// Elenco di tag delle competenze soft
		$scope.softCompetences = [];

		// Mix con l'elenco alternato di competenze soft e tech
		$scope.combinedCompetences = [];

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se è un progetto
		$scope.isProject = false;

		// Verifica se l'item è stato completato
		$scope.isConsumed = false;

		// Verifica se è una sezione
		$scope.isSection = false;

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Verifica se è una competenza tecnica
		$scope.isTechSkill = false;

		// Verifica se è una competenza soft
		$scope.isSoftSkill = false;

		// Valore formativo
		$scope.hourValue = null;

		// Oggetto aperto
		$scope.isItemOpen = false;

		// Persona che ha suggerito l'elemento
		$scope.suggestedPerson = null;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Collegamento agli oggetti principali
		$scope.globalApplicationData = GlobalApplicationData;
		$scope.bgAcademyApplicationData = BgacademyApplicationData;
		// Configurazione chart con la percentuale del completamento piano
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff',
			lineWidth: 2,
			lineCap: 'butt',
			size: 75
		}

		// Di default l'item è abilitato
		$scope.isItemDisabled = false;

		if ($scope.propedeuticReferenceId && !$scope.isNotDisabled && !$state.includes('app.bgacademyApp.editContainerItemAdmin') && !$state.includes('app.bgacademyApp.createContainerItemAdmin')) {
			$scope.isItemDisabled = true;
		}

		if (!$scope.isItemDisabled) {
			$scope.containerClass += ' cursor-default';
		} else {
			$scope.containerClass += ' cursor-pointer';
			$scope.opacityLayerClass = 'opacity-layer';
			$scope.bgDisabledClass = 'bg-disable';
			$scope.textDisabledClass = 'text-disable';
			$scope.textWhiteDisabledClass = 'text-white';
		}

		// Controllo se l'oggetto è obbligatorio
		$scope.checkIfRequired = () => {
			if ($scope.mandatory == 1 || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isRequired = true;
			} else {
				$scope.isRequired = false;
			}
		}

		$scope.checkIfRequired();

		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.item && $scope.item.title.length < 62) {
			$scope.item.titleClass = 'card-title-h3';
		} else if ($scope.item && $scope.item.title.length > 62 && $scope.item.title.length > 62) {
			$scope.item.titleClass = 'card-title-h2';
		}

		// Verifico gli attributi dell'oggetto
		if ($scope.item) {
			if ($scope.item.itemAttributes) {
				let itemAttributes = $scope.item.itemAttributes;
				for (let k = 0; k < itemAttributes.length; k++) {
					// Verifico se è una competenza soft
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_SKILL) {
						$scope.isSoftSkill = true;
					}
					// Verifico se è una competenza tecnica
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.TECHNICAL_SKILL) {
						$scope.isTechSkill = true;
					}

					// Verifico il tipo di oggetto
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.OBJECT_TYPE) {
						$scope.itemType = itemAttributes[k].attributeValue;
						if (itemAttributes[k].attributeValue == BgacademyApplicationData.constants.LEARNING_PLAN) {
							$scope.isLearningPlan = true;
						} else if (itemAttributes[k].attributeValue == BgacademyApplicationData.constants.PROJECT) {
							$scope.isProject = true;
						} else if (itemAttributes[k].attributeValue == BgacademyApplicationData.constants.SECTION) {
							$scope.isSection = true;
						}
					}

					// Verifico se c'è l'immagine di copertina
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.CARD_COVER.ITEM_TYPE) {
						$scope.cardCover = itemAttributes[k].attributeValue;
					}

					// Verifico se è MiFID
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.MIFID_II_TYPE && itemAttributes[k].attributeType) {
						$scope.isMifid = true;
					}

					// Verifico se c'è il valore formativo
					if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.VALUE) {
						let duration = moment.duration(parseInt(itemAttributes[k].attributeValue), 'seconds');
						$scope.hourValue = {
							hours: duration.minutes(),
							minutes: duration.seconds()
						}
					}

					// Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if (itemAttributes[k].crossReferenceObject) {
						if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.TECH_COMPETENCES) {
							$scope.techCompetences.push(itemAttributes[k].crossReferenceObject.title);
						} else if (itemAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_COMPETENCES) {
							$scope.softCompetences.push(itemAttributes[k].crossReferenceObject.title);
						}
					}
				}
				// Se non c'è l'immagine della card ne metto una di default
				if (!$scope.cardCover) {
					$scope.cardCover = '../assets/img/img-card.png'
				}
			}

			// Elenco contenente un mix alternato di competenze tecniche e soft
			for (var i = 0, len = Math.max($scope.techCompetences.length, $scope.softCompetences.length); i < len; i++) {
				if (i < $scope.techCompetences.length) {
					$scope.combinedCompetences.push($scope.techCompetences[i]);
				}
				if (i < $scope.softCompetences.length) {
					$scope.combinedCompetences.push($scope.softCompetences[i]);
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.libraryCard && $scope.libraryCard.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.libraryCard.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			if ($scope.item.engagements) {
				for (let d = 0; d < $scope.item.engagements.length; d++) {
					// Verifico se l'item è stato concluso 
					if ($scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED) {
						$scope.isConsumed = true;
					}

					//let found = false;
					if ($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN) {
						// Oggetto aperto
						$scope.isItemOpen = true;
					}

					if ($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && (
						$scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED ||
						$scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_STARTED)) {
						// Recupero la percentuale
						if (!hasItemRegistration && !hasScormRegistration && $scope.item.engagements[d].engagementDetails) {
							for (let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++) {
								if ($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE') {
									$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
									//found = true;
									break;
								}
							}
						}
					}
					else if ($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN) {
						// Oggetto aperto
						$scope.isItemOpen = true;
					}
					//if (found) {
					//	break;
					//}

					// Verifico la certificazione
					if ($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CERTIFIED) {
						// L'oggetto è già stato certificato
						$scope.isItemAlreadyCertified = true;
					}
				}

				// Se l'item è consumed, mostro la percentuale al 100%
				if ($scope.isConsumed && !hasScormRegistration && !hasItemRegistration) {
					$scope.percentageItem = "100";
				}
			}

			// TODO-Allloy cerco anche fra i dati che ora mi mancano (certificazione, etc)
			if ($scope.isItemOpen || $scope.percentageItem || $scope.isItemAlreadyCertified) {
				$scope.defaultOnSuggestion = false;
			}

			if ($scope.item.itemVisibilities) {
				// Ordino le visibilities per data
				$scope.item.itemVisibilities.sort((a: any, b: any) => {
					if (a.creationDate > b.creationDate) {
						return -1;
					} else if (a.creationDate < b.creationDate) {
						return 1;
					} else {
						return 0;
					}
				});
				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for (let j = 0; j < $scope.item.itemVisibilities.length; j++) {
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if ($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true) {
						$scope.suggestedFromAdmin = true;
						$scope.hasSuggested = true;
						break;
					} else if ($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION) {
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for (let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if (groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)) {
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.item.itemVisibilities[j].userObject;
							break;
						}
					}
				}
			}
		}

		// Di default l'item è abilitato
		$scope.isItemDisabled = false;

		// Controllo quale icona mostrare
		$scope.iconToShow = "EMPTY";
		$scope.iconToShowAlt = "EMPTY";
		// Versione standard
		if ($scope.propedeuticReferenceId && !$state.includes('app.bgacademyApp.editContainerItemAdmin') && !$state.includes('app.bgacademyApp.createContainerItemAdmin')) {
			// Disabilito l'item corrente se ce n'è un'altro obbligatorio
			$scope.iconToShow = "DISABLED";
			$scope.isItemDisabled = true;
		}
		else if ($scope.isItemAlreadyCertified) {
			$scope.iconToShow = "CERTIFICATE";
		}
		else if ($scope.percentageItem) {
			$scope.iconToShow = "PERCENTAGE";
		}
		else if ($scope.isItemOpen) {
			$scope.iconToShow = "OPEN";
		}
		/*
		else if (scope.suggestedFromLibrary) {
			$scope.iconToShow = "LIBRARY";
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		// Versione con forza persona
		if ($scope.propedeuticReferenceId && !$state.includes('app.bgacademyApp.editContainerItemAdmin') && !$state.includes('app.bgacademyApp.createContainerItemAdmin')) {
			// Disabilito l'item corrente se ce n'è un'altro obbligatorio
			$scope.iconToShowAlt = "DISABLED";
			$scope.isItemDisabled = true;
		}
		else if ($scope.forceToggleIndicator && $scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.forceToggleIndicator && $scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		else if ($scope.isItemAlreadyCertified) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "CERTIFICATE";
			}
		}
		else if ($scope.percentageItem) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "PERCENTAGE";
			}
		}
		else if ($scope.isItemOpen) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "OPEN";
			}
		}
		/*
		else if (scope.suggestedFromLibrary) {
			if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested){
			$scope.iconToShowAlt = "PERSON";
			}
			else {
			$scope.iconToShowAlt = "LIBRARY";
			}
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}

		// Mostra l'oggetto propedeutico
		$scope.showPropedeuticalItem = () => {
			if ($scope.isItemDisabled) {
				$scope.showPropedeuticalItemToThis($scope.libraryCard);
			}
		}

		// Porta alla pagina di dettaglio dell'oggetto selezionato
		$scope.goToItemDetail = (itemId: string) => {
			// Se l'item è disabilitato, devo far vedere gli oggetti propedeutici
			if ($scope.isItemDisabled) {
				$scope.showPropedeuticalItem();
			} else {
				if ($state.includes('app.bgacademyApp.playlistDetailUser')) {
					// Caso in cui sto andando nel dettaglio di un oggetto di una Plyalist lato utente
					$state.go("app.bgacademyApp.playlistItemDetailUser", { itemId: $scope.item.itemId, playlistId: $stateParams.itemId });
				} else if ($state.includes('app.bgacademyApp.playlistDetailAdmin')) {
					// Caso in cui sto andando nel dettaglio di un oggetto di una Plyalist lato admin
					$state.go("app.bgacademyApp.playlistItemDetailAdmin", { itemId: $scope.item.itemId, playlistId: $stateParams.itemId });
				} else if ($state.includes('app.bgacademyApp.editContainerItemAdmin') || $state.includes('app.bgacademyApp.createContainerItemAdmin') || $state.includes('app.bgacademyApp.editSectionContainerItemAdmin')) {
					return;
				} else if (itemId != $scope.item.itemId) {
					$state.go("app.bgacademyApp.itemDetail", { itemId: $scope.item.itemId, backAction: $state.current.name });
				} else {
					$state.go("app.bgacademyApp.itemDetail", { itemId: itemId, backAction: $state.current.name });
				}
			}
		}
	}
});